import React from 'react';
import Icon from 'icon';

import s from './container.module.scss';

function HeroSymbol({ classes }) {
  return (
    <span className={`${s.symbolWrap} ${classes}`}>
      <span className={s.iconWrap}>
        <Icon icon="chevron-down" />
      </span>
    </span>
  );
}

export default React.forwardRef(
  (
    {
      children,
      className,
      noSection,
      primary,
      secondary,
      grey,
      symbolTop,
      symbolBottom,
      ...props
    },
    heroRef
  ) => {
    const primaryClasses = primary ? s.primary : '';
    const secondaryClasses = secondary ? s.secondary : '';
    const greyClasses = grey ? s.grey : '';
    const colorClasses = primaryClasses || secondaryClasses || greyClasses;
    const symbolTopClasses = symbolTop ? s.symbolTop : '';
    const symbolBottomClasses = symbolBottom ? s.symbolBottom : '';
    const symbolClasses = symbolTopClasses || symbolBottomClasses;
    const classes = `${s.container} ${className ||
      ''} ${colorClasses} ${symbolClasses}`;
    return noSection ? (
      <div className={`container ${className || ''}`} ref={heroRef}>
        {children}
      </div>
    ) : (
      <section className={classes} {...props} ref={heroRef}>
        {symbolClasses && <HeroSymbol classes={symbolClasses} />}
        <div className="container">{children}</div>
      </section>
    );
  }
);
